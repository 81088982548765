import VfStar from "./vectors/VfStar";
import Vf2023Tick from "./vectors/Vf2023Tick";
import CustomQuestion from "./vectors/CustomQuestion";
import LocationPinIcon from "./vectors/LocationPinIcon";
import CallIcon from "./vectors/CallIcon";
import UserIcon from "./vectors/UserIcon";
import EyeIcon from "./vectors/EyeIcon";

const icons = {
  star: <VfStar />,
  eye: <EyeIcon />,
  person: <UserIcon />,
  phone: <CallIcon />,
  check: <Vf2023Tick />,
  location: <LocationPinIcon />,
  question: <CustomQuestion />
};

const CustomIcon = ({ icon, color, classes = 'm-4 mt-2 w-8 h-8' }) => {
  const colours = {
    'red': 'text-cherry-red',
    'blue': 'text-secondary-blue',
    'green': 'text-grass-green',
    'mint': 'text-mint-green',
    'navy': 'text-primary-blue'
  }

  return (
    <div className={`flex items-center flex-col justify-start`}>
      <div className={`${colours[color]} ${colours[color]?.replace('text', 'border')} ${classes} flex items-center justify-center p-1 vfIcon border-[3px] border-solid rounded-full`}>
        {icons[icon]}
      </div>
    </div>
  );
};

export default CustomIcon;
